import { getLocalStorage, isAuth } from "@/actions/auth"
import { ContactService } from "@/actions/crm/contact-controller"
import { KEY_LOCAL_STORAGE } from "@/components/Header/libs/interfaces-enums"
import { generateDefaultContactFields } from "@/constants/crm/contactForm"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import {
  get,
  getContactsByUser,
  getInteractionsContact
} from "../../actions/crm/contact"
import { handleAddInteraction, handleChangeLocalStatus, handleMenuInformation } from "../actions/crm"
import { PayloadActionChangeLocalStatus } from "../libs/ts/types"

const initialState: any = {
  activeContacts: [],
  reminderContacts: [],
  archiveContacts: [],
  allContacts: [],
  list: 0,
  contact: null,
  interactions: [],
  sellers: [],
  error: null,
  agentsToTransfer: [],
  userInbox: 'me',
  moreContacts: false,
  moreChats: false,
  replyMessage: false,
  action: "select",
  openCreateContact: false,
  sectionInformationContact: 'information',
  requestsaleid: undefined,
  requestsaletemplateid: undefined,
  archiveFilter: "all",
  iframeOpen: false,
  uploadStep: 0,
  localInteractionId: 0,
  uploadContacts: [],
  uploadedContacts: [],
  uploadContactUpdate: false,
  openCallReminderModal: false,
  openMenuInformation: false,
  loadingList: false,
  isLoadingContact: true,
  fetchingId: null,
  resetCountContactUnassigned: null,
  isLoadingFilteredInteractions: false,
  activeChannel: null,
  contactUnassignedDate: undefined,
  contactRequest: [],
  fileNames: { test: "Ventia" },
  response: null,
  contactsSelected: [],
  subConversations: [],
  socketId: null,
  privateSocketId: null,
  dataReminder: null,
  listOfContactsInMap: [],
  menuInformationControl: getLocalStorage(KEY_LOCAL_STORAGE.MENU_INFORMATION_CONTROL, true) || [],
  actionMasiveSelected: null,
  isOpenReminderMenu: false,
  activeInteractionFilters: {},
  searchValue: '',
  staticTexts: {},
  loadingUploadFile: false,
  multimediaToSend: {
    type: null,
    multimedia: []
  },
  reply: null,
  isActiveTemplateMode: false,
  searchTemplate: '',
  matchTemplate: '',
  selectedTemplate: '',
  transferableUsers: [],
  newInteractionsId: {},
  scheduleMessageDate: null,
  inputProperties: {
    height: 150
  },
  createCampaignFromContactsData: {
    name: '',
    contactsId: []
  },
  contactForm: generateDefaultContactFields(),
  enableSaveRequestSale: true,
  extraContactsFilter: {},
  showUnmaneged: false,
  onceFetchingContactForm: false,
  disabledCreateContactButton: false,
  requestSale: {
    templates: [],
    active: false,
    onceFetching: false,
    isLoading: true
  },
  sharedInbox: null,
  sharedInboxUser: null,
}

export const fetchContacts = createAsyncThunk<
  void,
  {
    actAs: string
    limit?: number
    name: string
    search: string
    page?: number
    lastDate?: Date | false
    addToList?: boolean
    archiveFilter: string
    extraFilters: { [filter: string]: string }
  }
>("crm/fetchContacts", async (query, { dispatch }) => {
  try {

    const {
      name,
      search,
      archiveFilter,
      extraFilters,
      addToList,
      lastDate,
      page,
      limit,
      actAs,
    } = query

    if (!addToList) dispatch(setLoadingList(true))

    getContactsByUser({
      actAs: actAs === 'me' ? _.get(isAuth(), '_id', '') : actAs,
      archiveFilter,
      extraFilters,
      lastDate,
      type: 'status' in extraFilters ? '' : name,
      limit: limit || 50,
      page: page || 0,
      search,
    }).then((result) => {
      dispatch(setLoadingList(false))
      if (!result)
        return dispatch(setError("Error con la conexion al servidor"))
      if (result.error) return dispatch(setError(result.error))

      const pageIsGreaterCeroOrAddToList = (page && page > 0) || addToList

      if (result.length < (limit || 50)) {
        dispatch(setMoreContacts(false))
      } else {
        dispatch(setMoreContacts(true))
      }
      switch (name) {
        case "active":
          if (pageIsGreaterCeroOrAddToList) {
            dispatch(addMultipleActiveContacts(result))
          } else {
            dispatch(setActiveContacts(result))
          }
          break
        case "reminder":
          if (pageIsGreaterCeroOrAddToList) {
            dispatch(addMultipleReminderContacts(result))
          } else {
            dispatch(setReminderContacts(result))
          }
          break
        case "closed":
          if (pageIsGreaterCeroOrAddToList) {
            dispatch(addMultipleArchiveContacts(result))
          } else {
            dispatch(setArchiveContacts(result))
          }
          break

        case "all":
          if (pageIsGreaterCeroOrAddToList) {
            dispatch(addMultipleAllContacts(result))
          } else {
            dispatch(setAllContacts(result))
          }
          break
        default:
          dispatch(setError("El tipo " + query.name + " no existe"))
      }
    })
  } catch (err) {
    dispatch(setError("Error al actualizar la lista de contactos"))
  }
})

export const fetchContact = createAsyncThunk<void, { id: string, query?: Record<string, any> }>(
  "crm/getContact",
  async ({ id, query = {} }, { dispatch }) => {
    dispatch(setLoadingContact(true))
    dispatch(setFetchingId(id))

    try {
      get(id, query).then((p) => {

        if (!p || p.error) {
          dispatch(setLoadingContact(false))
          return dispatch(setError("Error al obtener info del contacto"))
        }

        dispatch(setContact(p))
        dispatch(setLoadingContact(false))
        dispatch(setEmptyInteractionsId(null))

        if (p.interactions.length < 50) {
          dispatch(setMoreChats(false))
        } else {
          dispatch(setMoreChats(true))
        }
      })
    } catch (err) {
      dispatch(setError("Error al obtener info del contacto"))
    }
  }
)

export const fetchMoreInteractionsContact = createAsyncThunk<
  void,
  { id: string; date: string }
>("crm/getMoreInteractionsContact", async ({ id, date }, { dispatch }) => {
  try {
    getInteractionsContact(id, date).then((interactions) => {
      if (!interactions) dispatch(setError("Error a cargar el chat"))
      if (interactions.error) dispatch(setError("Error a cargar el chat"))
      dispatch(addInteractionsContact({ contact: id, interactions }))

      if (interactions.length < 50) {
        dispatch(setMoreChats(false))
      } else {
        dispatch(setMoreChats(true))
      }
    })
  } catch (err) {
    dispatch(setError("Error al obtener info del contacto"))
  }
})

export const getInteractions = createAsyncThunk<void, any>(
  "crm/getInteractions",
  async (
    { filters, searchValue, id, isLoadMore = false, limit = 50, page = 1, date },
    { dispatch }
  ) => {
    dispatch(setLoadingFilteredInteractions(true))
    const params = {
      id,
      limit,
      date,
      page,
      search: searchValue,
      type: filters["type"],
    }

    ContactService.getInteractions(params)
      .then((interactions) => {
        if (!interactions) dispatch(setError("Error a cargar el interacciones"))

        if (isLoadMore) {
          dispatch(addInteractionsContact({ contact: id, interactions }))
        } else {
          dispatch(setFilteredInteractions(interactions))
        }

        if (interactions.length < 50) {
          dispatch(setMoreChats(false))
        } else {
          dispatch(setMoreChats(true))
        }
      })
      .catch(() => {
        dispatch(setError("Error al obtener las interecciones"))
      })
      .finally(() => {
        dispatch(setLoadingFilteredInteractions(false))
      })
  }
)

export const handleUpdate = createAsyncThunk<void, any>(
  "crm/handleUpdate",
  async (info, { dispatch, getState }) => {
    try {
      const state: any = getState()
      const { options } = info || {}
      if (
        info.in == "interaction" &&
        state.crm.contact &&
        (info?.contact && info.contact == state.crm.contact._id)
      ) {
        if (info.type == "new") {
          dispatch(addInteraction(info.content))
        } else if (info.type == "mod") {
          dispatch(updateInteraction(info))
        }
      }
      if (info.in == "contact" && info.type == "new") {
        let content = info.content
        content.interactions = []
        dispatch(addActiveContact(content))
      }
      if (info.in == "contact" && info.type === "remove") {
        dispatch(removeContact(info.contact))
      }

      if (info.in == "contact" && info.type == "mod") {
        let content = info.content

        const getUpdatedObjectContact = (contact: any) => {
          const { channels, channelsList, bloquedChannels, ...updateContact } = contact;
          return updateContact
        }

        if (options?.massive && Array.isArray(content))
          return content.forEach(contact => dispatch(modContact(getUpdatedObjectContact(contact))))

        dispatch(modContact(getUpdatedObjectContact(content)))
      }
      if (info.in == "interaction") {
        dispatch(updateInteractionList(info))
      }

      if (info.in == "outboundcall") {
        console.log("Llamaria con el siguiente info", info)
      }
    } catch (err) {
      dispatch(setError("Error al actualizar"))
    }
  }
)

export const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {
    addActiveContact: (state, action) => {
      let indexAction = state.activeContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload._id)
      if (indexAction >= 0) {
        state.activeContacts.splice(indexAction, 1)
      }
      state.activeContacts = [action.payload, ...state.activeContacts]
    },
    successFetchContactsUpload: (state, action) => {
      state.uploadStep = 1
      state.uploadContacts = action.payload
    },
    setSelectedContact: (state, action) => {
      const id = action.payload
      const contactsShadowCopy = [...state.contactsSelected]
      const findContactIndex = contactsShadowCopy.indexOf(id)
      findContactIndex !== -1
        ? contactsShadowCopy.splice(findContactIndex, 1)
        : contactsShadowCopy.push(id)
      state.contactsSelected = contactsShadowCopy
    },
    setDeleteContact: (state, action) => {
      const contactsShadowCopy = [...state.contactsSelected]
      const findContactIndex = contactsShadowCopy.indexOf(action.payload)
      contactsShadowCopy.splice(findContactIndex, 1)
      state.contactsSelected = contactsShadowCopy
    },
    setDeselectAllContacts: (state) => {
      state.contactsSelected = []
    },
    setResponse: (state) => {
      state.contactsSelected = []
    },
    setSelectedActionMasive: (state, action) => {
      state.actionMasiveSelected = action.payload
    },
    setToggleCreateContact: (state, action) => {
      state.openCreateContact = action.payload
    },
    setIncrementalLocalId: (state) => {
      state.localInteractionId += 1
    },
    goBackContactsUpload: (state) => {
      state.uploadStep--
    },
    goBackToContactsUpload: (state) => {
      state.uploadStep = 0
    },
    successCreateContactsUpload: (state, action) => {
      state.uploadStep = 2
      state.uploadedContacts = action.payload
    },
    addFileName: (state, action) => {
      if (!state.fileNames) {
        state.fileNames = { [action.payload.name]: action.payload.value }
      } else {
        state.fileNames[action.payload.name] = action.payload.value
      }
    },
    resetContactUpload: (state) => {
      state.uploadStep = 0
      state.uploadedContacts = []
      state.uploadContacts = []
    },
    addMultipleActiveContacts: (state, action) => {
      state.activeContacts = [...state.activeContacts, ...action.payload]
    },
    addMultipleReminderContacts: (state, action) => {
      state.reminderContacts = [...state.reminderContacts, ...action.payload]
    },
    addMultipleArchiveContacts: (state, action) => {
      state.archiveContacts = [...state.archiveContacts, ...action.payload]
    },
    addMultipleAllContacts: (state, action) => {
      state.archiveContacts = [...state.allContacts, ...action.payload]
    },
    setActiveContacts: (state, action) => {
      state.activeContacts = action.payload
    },
    setList: (state, action) => {
      state.list = action.payload
      if (state.archiveFilter != "all" && action.payload != 3) {
        state.archiveFilter = "all"
      }
    },
    setLoadingList: (state, action) => {
      state.loadingList = action.payload
    },
    setUploadContactUpdate: (state, action) => {
      state.uploadContactUpdate = action.payload
    },
    setReply: (state, action) => {
      if (action.payload) {
        switch (action.payload.type) {
          case "unofficial-whatsapp":
            state.action = "unofficial-whatsapp"
            break;
          case 'channel-message':
            state.action = 'channel-message'
            break;
          default:
            state.action = "select"
        }
        state.reply = {
          id: action.payload._id,
          message: action.payload.content.message,
        }
      } else {
        state.reply = null
      }
    },
    setArchiveFilter: (state, action) => {
      state.archiveFilter = action.payload
    },
    setMenuInformationControl: (state, action) => handleMenuInformation(state, action),
    setReminderContacts: (state, action) => {
      state.reminderContacts = action.payload
    },
    addReminderContacts: (state, action) => {
      state.reminderContacts = [action.payload, ...state.reminderContacts]
    },
    setListOfContactInMap: (state, action) => {
      state.listOfContactsInMap = action.payload
    },
    setArchiveContacts: (state, action) => {
      state.archiveContacts = action.payload
    },
    setAllContacts: (state, action) => {
      state.allContacts = action.payload
    },
    setStaticTexts: (state, action) => {
      const { id, text } = action.payload
      state.staticTexts[id] = text
    },
    addArchiveContacts: (state, action) => {
      state.archiveContacts = [action.payload, ...state.archiveContacts]
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setContact: (state, action) => {
      state.contact = action.payload
    },
    addInteraction: (state, action) => handleAddInteraction(state, action),
    handleSetLocalStatus: (state, action: PayloadActionChangeLocalStatus) => handleChangeLocalStatus(state, action),
    setAction: (state, action) => {
      state.action = action.payload
      state.reply = null
      state.isActiveTemplateMode = false
      state.searchTemplate = ''
      state.isSelectingTemplate = false
      state.selectedTemplate = ''
    },
    setActiveChannel: (state, action) => {
      state.activeChannel = action.payload.config
      state.action = action.payload.action
    },
    setAgentsToTransfer: (state, action) => {
      state.agentsToTransfer = action.payload
    },
    setRequestSaleId: (state, action) => {
      state.requestsaletemplateid = undefined
      state.requestsaleid = action.payload
    },
    toggleOpenRequestSale: (state, action) => {
      state.requestsaleid = true
      state.requestsaletemplateid = action.payload
    },
    openRequestSale: (state, action) => {
      state.requestsaleid = action.payload.id
      state.requestsaletemplateid = action.payload.templateid
    },
    setContactUnassignedDate: (state, action) => {
      state.contactUnassignedDate = action.payload
    },
    setMoreContacts: (state, action) => {
      state.moreContacts = action.payload
    },
    setMoreChats: (state, actions) => {
      state.moreChats = actions.payload
    },
    setResetCountContactUnassigned: (state, actions) => {
      state.resetCountContactUnassigned = state.resetCountContactUnassigned == null
        ? actions.payload :
        !state.resetCountContactUnassigned
    },
    setSocketId: (state, actions) => {
      state.socketId = actions.payload
    },
    setPrivateSocketId: (state, actions) => {
      state.privateSocketId = actions.payload
    },
    setScheduleMessageDate: (state, actions) => {
      state.scheduleMessageDate = actions.payload
    },
    updateInteraction: (state, action) => {
      let index = state.contact.interactions
        .findIndex((interaction) => interaction._id == action.payload.content._id);

      if (index >= 0) {
        const { agent, ...dataToUpdate } = action.payload.content
        state.contact.interactions[index] = { ...state.contact.interactions[index], ...dataToUpdate };
      }
    },
    updateInteractionList: (state, action) => {
      let indexAction = state.activeContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload.contact)
      let indexArchive = state.archiveContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload.contact)
      let indexReminder = state.reminderContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload.contact)
      if (indexAction >= 0) {
        state.activeContacts[indexAction].interactions.unshift(
          action.payload.content
        )
      }
      if (indexArchive >= 0) {
        state.archiveContacts[indexArchive].interactions.unshift(
          action.payload.content
        )
      }
      if (indexReminder >= 0) {
        state.reminderContacts[indexReminder].interactions.unshift(
          action.payload.content
        )
      }
    },
    removeContact: (state, action) => {
      let indexAction = state.activeContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload)
      let indexArchive = state.archiveContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload)
      let indexReminder = state.reminderContacts
        .map(({ _id }) => _id)
        .indexOf(action.payload)

      if (indexAction >= 0) {
        state.activeContacts.splice(indexAction, 1)
      } else if (indexArchive >= 0) {
        state.archiveContacts.splice(indexArchive, 1)
      } else if (indexReminder >= 0) {
        state.reminderContacts.splice(indexReminder, 1)
      }
    },
    setSellers: (state, action) => {
      state.sellers = action.payload
    },
    setUserInbox: (state, action) => {
      state.userInbox = action.payload
    },
    setEnableSaveRequestSale: (state, action) => {
      state.enableSaveRequestSale = action.payload
    },
    setIframeOpen: (state, action) => {
      state.iframeOpen = action.payload
    },
    handleRepyMessage: (state, action) => {
      state.replyMessage = action.payload
    },
    modContact: (state, action) => {
      let activeContactsMap = _.keyBy(state.activeContacts, '_id');
      let archiveContactsMap = _.keyBy(state.archiveContacts, '_id');
      let reminderContactsMap = _.keyBy(state.reminderContacts, '_id');

      const { _id: contactId, archivingReason, reminder } = action.payload;

      let lastContactValue: { [key: string]: any } = activeContactsMap[contactId] ||
        archiveContactsMap[contactId] ||
        reminderContactsMap[contactId] ||
        {};

      if (activeContactsMap[contactId]) {
        delete activeContactsMap[contactId];
        state.activeContacts = Object.values(activeContactsMap);
      };

      if (reminderContactsMap[contactId]) {
        delete reminderContactsMap[contactId];
        state.reminderContacts = Object.values(reminderContactsMap);
      };

      if (archiveContactsMap[contactId]) {
        delete archiveContactsMap[contactId];
        state.archiveContacts = Object.values(archiveContactsMap);
      };

      const updatedContact = { ...(lastContactValue || {}), ...action.payload };

      if (!updatedContact.interactions || !Array.isArray(updatedContact.interactions)) {
        updatedContact.interactions = [];
      };

      if (archivingReason && archivingReason !== "") {
        state.archiveContacts.unshift(updatedContact)
      } else if (reminder && reminder !== "") {
        state.reminderContacts.unshift(updatedContact)
      } else {
        state.activeContacts.unshift(updatedContact)
      };

      if (state?.contact?._id === contactId) {
        ["reminder", "archivingReason", "assignedGroups", "lockedBy", "lockedAt"].forEach((key) => {
          if (!action.payload[key]) {
            state.contact[key] = undefined;
          }
        });
        state.contact = { ...state.contact, ...action.payload };
      }
    },
    addInteractionsContact: (state, action) => {
      if ((state.contact._id = action.payload.contact)) {
        state.contact.interactions = [
          ...state.contact.interactions,
          ...action.payload.interactions,
        ]
      }
    },
    setFilteredInteractions: (state, action) => {
      state.contact.interactions = action.payload
    },
    setTransferableUsers: (state, action) => {
      state.transferableUsers = action.payload
    },
    setReminderData: (state, action) => {
      state.dataReminder = action.payload
    },
    setOpenReminderMenu: (state, action) => {
      state.isOpenReminderMenu = action.payload
    },
    setOpenReminderCall: (state, action) => {
      state.openCallReminderModal = action.payload
    },
    setLoadingContact: (state, action) => {
      state.isLoadingContact = action.payload
    },
    setFetchingId: (state, action) => {
      state.fetchingId = action.payload
    },
    setLoadingFilteredInteractions: (state, action) => {
      state.isLoadingFilteredInteractions = action.payload
    },
    setFilterInteraction: (state, action) => {
      state.activeInteractionFilters = action.payload
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    setSectionInformationContact: (state, action) => {
      state.sectionInformationContact = action.payload
    },
    setUploadMultimediaType: (state, action) => {
      state.multimediaToSend = action.payload
    },
    setContactRequest: (state, action) => {
      state.contactRequest = action.payload
    },
    setSubConversations: (state, action) => {
      state.subConversations = action.payload
    },
    setStatusTag: (state, action) => {
      state.contact.statusTag = action.payload
    },
    setLoadingUploadFile: (state, action) => {
      state.loadingUploadFile = action.payload
    },
    setTemplateMode: (state, action) => {
      state.isActiveTemplateMode = action.payload
    },
    setOpenMenuInformation: (state, action) => {
      state.openMenuInformation = !!action.payload
    },
    setSearchTemplate: (state, action) => {
      state.searchTemplate = action.payload
    },
    setMatchTemplate: (state, action) => {
      state.matchTemplate = action.payload
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload
    },
    setEmptyInteractionsId: (state, _) => {
      state.newInteractionsId = {}
    },
    setTemplateReset: (state) => {
      state.isActiveTemplateMode = false
      state.isSelectingTemplate = false
      state.searchTemplate = ''
      state.selectedTemplate = ''
      state.setMatchTemplate = ''
    },
    setCreateCampaignFromContacts: (state, action) => {
      state.createCampaignFromContactsData = action.payload
    },
    setContactForm: (state, action) => {
      state.contactForm = action.payload
    },
    setInputProperty: (state, action) => {
      state.inputProperties = action.payload
    },
    setExtraContactsFilter: (state, action) => {
      state.extraContactsFilter = action.payload
    },
    setShowUnmaneged: (state, action) => {
      state.showUnmaneged = action.payload
    },
    setOnceFetchingContactForm: (state, action) => {
      state.onceFetchingContactForm = action.payload
    },
    setDisabledCreateContactButton: (state, action) => {
      state.disabledCreateContactButton = action.payload
    },
    setRequestSale: (state, action) => {
      state.requestSale = action.payload
    },
    setSharedInbox: (state, action) => {
      state.sharedInbox = action.payload
    },
    setSharedInboxUser: (state, action) => {
      state.sharedInboxUser = action.payload
    }
  },
})

export const {
  addMultipleArchiveContacts,
  setSharedInbox,
  addFileName,
  setOnceFetchingContactForm,
  setSubConversations,
  setUploadContactUpdate,
  setActiveChannel,
  setLoadingList,
  setDisabledCreateContactButton,
  setRequestSale,
  goBackContactsUpload,
  setExtraContactsFilter,
  resetContactUpload,
  setCreateCampaignFromContacts,
  goBackToContactsUpload,
  successCreateContactsUpload,
  setSearchValue,
  setSharedInboxUser,
  successFetchContactsUpload,
  setIframeOpen,
  setArchiveFilter,
  setScheduleMessageDate,
  setInputProperty,
  setEnableSaveRequestSale,
  setShowUnmaneged,
  setResetCountContactUnassigned,
  setStatusTag,
  addMultipleAllContacts,
  setAllContacts,
  addMultipleReminderContacts,
  addActiveContact,
  removeContact,
  setMenuInformationControl,
  setMoreChats,
  setListOfContactInMap,
  addInteractionsContact,
  setSocketId,
  setStaticTexts,
  addMultipleActiveContacts,
  setOpenReminderCall,
  setContactRequest,
  modContact,
  setContactUnassignedDate,
  addArchiveContacts,
  setOpenMenuInformation,
  addReminderContacts,
  setLoadingFilteredInteractions,
  setMoreContacts,
  updateInteractionList,
  setActiveContacts,
  setList,
  setReminderContacts,
  setEmptyInteractionsId,
  setArchiveContacts,
  setError,
  setContact,
  setContactForm,
  setLoadingUploadFile,
  addInteraction,
  setFilteredInteractions,
  setTransferableUsers,
  updateInteraction,
  setAction,
  setRequestSaleId,
  openRequestSale,
  setPrivateSocketId,
  setSellers,
  setUserInbox,
  handleRepyMessage,
  setToggleCreateContact,
  setSelectedContact,
  setDeselectAllContacts,
  setSelectedActionMasive,
  setUploadMultimediaType,
  setDeleteContact,
  setReminderData,
  setAgentsToTransfer,
  setOpenReminderMenu,
  handleSetLocalStatus,
  setLoadingContact,
  setIncrementalLocalId,
  setFilterInteraction,
  setReply,
  setTemplateMode,
  setSectionInformationContact,
  setSearchTemplate,
  setMatchTemplate,
  setSelectedTemplate,
  setTemplateReset,
  setFetchingId,
  toggleOpenRequestSale,
} = crmSlice.actions

export default crmSlice.reducer

