export function initFacebookSdk() {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    if (typeof window !== "undefined") {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.NEXT_PUBLIC_META_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v15.0",
        })
      }

      console.log("fb init")
    }
    // load facebook sdk script
    if (typeof document !== "undefined") {
      ; (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = "https://connect.facebook.net/en_US/sdk.js"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "facebook-jssdk")
      console.log("fb script")
    }
  })
}

export const loginFB = async (setUserData, setError) => {
  // Launch Facebook login

  if (window.FB) {
    console.log(process.env.NEXT_PUBLIC_META_APP_ID)

    console.log(
      "pages_show_list,leads_retrieval,pages_manage_metadata,pages_manage_ads,business_management,pages_read_engagement"
    )

    await window.FB.login(
      await function (response) {
        if (response.authResponse) {
          const userData = {
            id: response.authResponse.userID,
            accessToken: response.authResponse.accessToken,
          }
          setUserData(userData)
        } else {
          setError("User cancelled login or did not fully authorize.")
        }
      },
      {
        scope:
          "pages_show_list,leads_retrieval,pages_manage_metadata,pages_manage_ads,business_management,pages_read_engagement,pages_messaging,public_profile",
      }
    )
  }
}

export const loginLeads = async (setUserData, setError) => {
  // Launch Facebook login

  if (window.FB) {
    console.log(process.env.NEXT_PUBLIC_META_APP_ID)
    console.log(
      "pages_show_list,leads_retrieval,pages_manage_metadata,pages_manage_ads,business_management,pages_read_engagement"
    )
    await window.FB.login(
      await function (response) {
        if (response.authResponse) {
          const userData = {
            id: response.authResponse.userID,
            accessToken: response.authResponse.accessToken,
          }
          setUserData(userData)
        } else {
          setError("User cancelled login or did not fully authorize.")
        }
      },
      {
        scope:
          "pages_show_list,leads_retrieval,pages_manage_metadata,pages_manage_ads,business_management,pages_read_engagement",
      }
    )
  }
}

// whatsapp_business_management => Permisos para la gestion de la cuenta de WhatsApp Business del usuario, esto nos permite para la gestion de plantillas dentro de Ventia
// whatsapp_business_messaging => Envio y recepción de mensajes;

export const loginWhatsAppBusiness = async (setAccountData, setError) => {
    if (window.FB) {
      await window.FB.login(
        await function (response) {
          if (response.authResponse) {
            setAccountData(response.authResponse)
          } else {
            setError("User cancelled login or did not fully authorize.")
          }
        },
        {
          response_type: "code",
          override_default_response_type: true,
          config_id: process.env.NEXT_PUBLIC_META_CONFIG_ID,
          extras: {
            setup: {},
            featureType: "",
            sessionInfoVersion: "2",
          },
        }
      )
    }
  }

export const loginInstagram = async (setUserData, setError) => {
  if (window.FB) {
    await window.FB.login(
      await function (response) {
        if (response.authResponse) {
          const userData = {
            id: response.authResponse.userID,
            accessToken: response.authResponse.accessToken,
          }

          setUserData(userData)
        } else {
          setError("User cancelled login or did not fully authorize.")
        }
      },
      {
        scope:
          "instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_show_list,pages_read_engagement,pages_messaging",
        return_scopes: true,
        extras: {
          setup: {
            channel: "IG_API_ONBOARDING",
          },
        },
      }
    )
  }
}

export const listenWaEmbeddedSignup = (
  onCaptureAuthCredentials,
  onCaptureCancel,
  onCaptureError
) => {
  window.addEventListener("message", (event) => {
    if (
      event.origin !== "https://www.facebook.com" &&
      event.origin !== "https://web.facebook.com"
    )
      return

    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          const { phone_number_id, waba_id } = data.data || {}

          onCaptureAuthCredentials &&
            onCaptureAuthCredentials({ phone_number_id, waba_id })
        } else if (data.event === "CANCEL") {
          const { current_step } = data.data
          onCaptureCancel && onCaptureCancel(current_step)
        } else if (data.event === "ERROR") {
          const { error_message } = data.data
          onCaptureError && onCaptureError(error_message)
        }
      }
    } catch (e) {
      console.error(e)
      console.log("Non JSON Responses", event.data)
    }
  })
}
