import { ReactNode } from "react"

export enum TYPE_MENUS {
    SELLERS = 'sellers',
    GENERAL = 'general'
}

export enum KEY_LOCAL_STORAGE {
    MENU_CONTROL = 'menu-control',
    MENU_INFORMATION_CONTROL = 'information-menu-control',
    USER = 'user'
}

export enum CODES_SUBMENUES {
    CHARTS = 'charts',
    CUSTOMERS = 'customers',
    BACKOFFICE = 'backoffice',
    CONVERSATIONS = 'conversations',
    CONTACTS = 'contacts'
}

export interface IPropsHeader {
    children: ReactNode
    overlayMode?: boolean
    SubMenu?: ReactNode | null
    containerStyle?: string
    collapsedMenu?: null | boolean
    optionOfMenues?: any
    widthOfSubMenu?: number | null
    logOutAPP?: (value: boolean) => void
    openMenu?: boolean
    chat?: boolean
    classNameResponsiveHeader?: string
    slug?: string
    rol?: any
    usuario?: any
}

export interface NavSubMenuProps {
    label: string
    notification?: number | null
    children: ReactNode
    open: Array<string>
    isExpandable?: boolean | null | undefined
    icon: ReactNode
    iconLabel?: ReactNode
    href: string
    value: string
    slug: string
    toolTipContent?: string | null
    isFirstSelect: boolean | undefined
    isCollapsedMenu: boolean
    contentSlugs?: Array<string>
    className?: string
}

export interface INavBarProps {
    icon: ReactNode
    label: string
    notification?: number | null | undefined
    className?: string
    href: string
    isCollapsedMenu?: boolean
    target?: string
    onAction?: () => void | undefined
    isSelected?: boolean
    slug?: string | undefined | null
    currentSlug?: string[]
}