import { createSlice } from '@reduxjs/toolkit';

// Mi idea es crear un estado global centralizado en donde nos permina  acceder a todos los datos de manera rapida sin la necesidad de tener que hacer una petición para queda uso, lo que voy a plantear es que en el campo "updatedDate" se almacene las fechas que se  han actualizado en el sistema, de esta manera podemos tener un historial de cambios en el sistema, si la fecha es bastante considerable, eliminaremos los valores para que este vuelva a regenerarse eh ir deprecando algunos estados que no mantienen un orden.

const initialState: any = {
    netPromoterScores: [],
    channels: [],
    roundRobins: [],
    updatedDate: {
        channels: null,
        netPromoterScores: null
    }
}

export const headersSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setChannels: (state, action) => {
            state.channels = action.payload;
        },
        setNetPromoterScores: (state, action) => {
            state.netPromoterScores = action.payload;
        },
        setRoundRobins: (state, action) => {
            state.roundRobins = action.payload;
        },
    }
})

export const {
    setChannels,
    setNetPromoterScores,
    setRoundRobins
} = headersSlice.actions

export default headersSlice.reducer