import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import main from "./reducers/main"
import massive from "./reducers/massive"
import administration from "./slices/administration"
import config from "./slices/config"
import crm from "./slices/crm"
import header from "./slices/headers"
import report from "./slices/report"
import user from "./slices/user"
import global from "./slices/global"

export default configureStore({
  reducer: {
    massive,
    main,
    crm,
    report,
    header,
    config,
    administration,
    user,
    global
  },
  middleware: [...getDefaultMiddleware()],
})
